<template>
  <div class="page-header clear-filter" filter-color="orange">
    <div
      class="page-header-image"
      style="background-image: url('img/header.jpg')"
    ></div>
    <div class="content">
      <div class="container">
        <div class="col-md-5 ml-auto mr-auto">
          <card type="login" plain>

            <h3>MISA Executive Board 2022-2023 Elections</h3>
            <div slot="header" class="logo-container">
              <img v-lazy="'img/now-logo.png'" alt="" />
            </div>
              <template slot="raw-content">
                <form @submit="handleSubmit">
                  <label class="custom-field one">
                    <input type="number" placeholder="Enter ID Number" v-model="id" onKeyPress="if(this.value.length==6) return false;" required/>
                  </label>
                  <card plain></card>
                  <button
                        type="submit"
                        class="btn btn-primary btn-round btn-lg btn-block"
                        >Login
                  </button>
                      
                </form>

              
                <div class="card-footer text-center">
                  
                  <a
                    href="/elections"
                    class="btn btn-default btn-round btn-lg btn-block"
                    >Back to Home</a
                  >
                </div>
              
              </template>
  
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button, FormGroupInput } from '@/components';
// import MainFooter from '@/layout/MainFooter';
export default {
  name: 'login-page',
  bodyClass: 'login-page',
  components: {
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      id:"",
      //idNumbers: ["182055", "182056"]
      idNumbers: ["190195", "192240", "190984", "191949", "195751", "201343", "205750", "194445", "200127", "202413", "200592", "194727", "195955", "205940", "205908", "191151", "192071", "190616", "193870", "202359", "195764", "200399", "204930", "192729", "195001", "200013", "205588", "192287", "195300", "201127", "205951", "202123", "201683", "211465", "211554", "216256", "213414", "203576", "206250", "212450", "213939", "211380", "204110", "210249", "211550", "203097", "216713", "216407", "210303", "203541", "201231", "196035", "216632", "216594", "203360", "216204", "201922", "204008", "203309", "196003", "190189", "202004", "216126", "195134", "210453", "212158", "210909", "195642", "203258", "193043", "192067", "190174", "215943", "200976", "195881", "206053", "191740", "204441", "214625", "211982", "201003", "213594", "210490", "211612", "215196", "211707", "214080", "210036", "212224", "204841", "205450", "200825", "201947", "205294", "212586", "212915", "204692", "200082", "196002", "192706", "216066", "213458", "200762", "210815", "214961", "200360", "214737", "216458", "213689", "213805", "215408", "215946", "191604", "211428", "214340", "193699", "215256", "214792", "204490", "205924", "211403", "211842", "215394", "210069", "194818", "194837", "190518", "203791", "204178", "195031", "214433", "213302", "213960", "194796", "205214", "210141", "216640", "203726", "216299", "191835", "205564", "215254", "215883", "210390", "215682", "194967", "213551", "214937", "214139", "215694", "216255", "204098", "193616", "195239", "210365", "195016", "210371", "210730", "214152", "215318", "214133", "212344", "200588", "210054", "213468", "214538", "213992", "211325", "211807", "194591", "214464", "210123", "215809"]
    }
  },
  methods: {
    handleSubmit() {
      if(this.idNumbers.includes(this.id)){
        this.$router.push("/ballot");
      }
      else{
        this.$router.push("/invalidID");
      }
      
    }
  }
};
</script>
<style>

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.custom-field {
  position: relative;
  font-size: 14px;
  border-top: 20px solid transparent;
  margin-bottom: 2px;
  --field-padding: 12px;
}

.custom-field input {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f2f2f2;
  padding: var(--field-padding);
  border-radius: 3px;
  width: 250px;
  outline: none;
  font-size: 14px;
  color: white;
}

::placeholder {
  color: white;
}


/* ONE */
.custom-field.one input {
  background: none;
  border: 2px solid #ddd;
  transition: border-color 0.3s ease;
}

.custom-field.one input + .placeholder {
  left: 8px;
  padding: 0 5px;
}



</style>
