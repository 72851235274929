<template>
<div>
    <div class="candidate-card" >
    <img :src="image">
        <div class="candidate-card-text">
          <h4>{{name}}</h4>
          <h5>{{position}}</h5>
          <n-button type="primary" @click="modals.classic = true">
              View Platform
          </n-button>
        </div>
        
    </div>
    

    <!-- Classic Modal -->
    <modal :show.sync="modals.classic" headerClasses="justify-content-center">
      <h4 slot="header" class="title title-up">Platform</h4>
      <h4>{{name}}</h4>
      <h5>{{position}}</h5>
      <img :src="image">
      <br>
      <br>
      <p><strong><i>View {{name}}’s application form, resume, cover letter, candidate photo, and portfolio (if applicable) through this <a :href="link" target="_blank">link</a>.</i></strong></p>
      
      <!-- platform for Jiron only -->
      <p v-if="name == 'Mark Jiron Rotoni'"><strong>The strategy I wish to implement is composed of internal and external platforms. The internal platform tackles different aspects within the communication cluster while the external platform talks about collaboration and connection outside the COMMs cluster. (organizational and corporate)

<br>
<br>INTERNAL PLATFORMS
<br>
<br>

<b>1. EASE</b>
<br>
<br>I'd like to implement a method that prevents officer burnout while also encouraging officer engagement and communication within the cluster.
<br>
<br>

<b>E</b>vents Interaction - <b>Communicate with Events regarding estimated event dates</b>. This will determine the time between each event that will allow me to come up with a <b>plan to disseminate tasks properly for officers</b>. This would <b>prevent overlapping events</b> from being deployed by the same officers and, if possible, evenly split all events to balance the time frames.
<br>
<br>

<b>A</b>uthorize Deployment - During COMM officer interviews, aspiring artists will be asked to <b>choose their preferred event deployments</b> with estimated time, date and duration. This will give them a chance to <b>work on publications during their time of availability</b>. From there, the COMMS BETA will conduct a meeting to collaborate about proper deployment for every officer.
<br>
<br>

<b>S</b>ettle with us! - Establish a good connection with new officers by conducting <b>hangouts (either weekly or once in two weeks) aided by Human Resources and Events </b>. Officers have the opportunity to <b>interact</b> with us, <b>ask questions</b> regarding their event deployment(quick overview of their assigned project conducted by Events) and hands-on <b>mentorship</b> for their assigned tasks.
<br>
<br>

<b>E</b>nforce policy and procedures - <b>Strict enforcement of protocols and times</b>. Blast Request Form and Publication Request forms to be submitted by Promotional Strategist or Creative Directors. Executive Board access to MISA social media pages. Approval of all social media posts from the VP for COMMS.
<br>
<br>
Additional Rules
<br>
If in case deployed officers are unavailable for a specific event deployment they must inform COMMS BETA regarding this. Officers can freely ask for guidance through Settle with us or conduct an IC if they need help with an assigned task.
<br>
<br>

<b>2. Establish specific visual identity for recurring events</b>
<br>
I would like to Implement a designated visual identity for all MISA events. The event theme must be appropriate to the target audience and would determine the target audience in order to encourage more people to join the event as well as establish uniformity when creating visuals for events.
<br>
<br>

Why? - This would be beneficial since people <b>could immediately recognize the logo trademark of each event</b>. They would be informed of the reference and infer that these events occur in a specific time frame. This would mean that this event is conducted only in MISA. At the same time, <b>create a path for aspiring graphic designers and creative directors</b> who want to <b>express themselves through art without limitations but also understand limited branding techniques</b> that would be essential in the corporate world.
<br>
<br>

How? - <b>Spearheading the design and brand of each event</b> such that MISA members can recall which event they are attending. New MISA members would not be confused in determining specific events.
<br>
<br>

Example on Formal Events:
<br>
IMSummit and Networking Night - Must each have a logo that represents their image, theme must be professionally and formally executed in a way that it tackles the agenda and goal of both events.
<br>
<br>

MISAyang Samahan - Already has its own logo that best represents the event objective so this should be continued.
<br>
<br>

<b>3. COMM Organization restructuring</b>
<br>
I plan to integrate more opportunities for MISA members that would allow them to go beyond their comfort zone. <b>By adding more officer positions</b> for them to make connections and practice their skills.
<br>
<br>

Why? - <b>Further develop the design industry by broadening the fields through technology</b>. UI/UX Design prototyping and web development will be incorporated(to be discussed on my external platform). Add more officer positions to address issues regarding low officer turnout. Encourage people to join MISA as a COMM officer since <b>the more positions offered the more chances people would apply and also further encourages more opportunities to learn</b>.
<br>
<br>

How? - <b>Create new officer positions for Product Designers (UI/UX)and Graphic Designers</b>. The role of a creative director will be modified. The CD will now be the point person for design.
<br>
<br>

Product Designers (In collaboration with eServices)
<br>
    - Responsible for Wireframing and Prototyping the design aspect of browsers. Create UI screens that fit the needs of their clients. Develop an understanding of the concept of UI/UX design through practice.
<br>
<br>
Graphic Designers
<br>
    - Responsible for creating visual concepts that fits the brand of MISA as well as develop the overall layout and design using illustrating softwares. They serve as the hand of MISA’s visual concept.
<br>
<br>

Creative Directors
<br>
    - He/she is responsible for checking if the publication is aligned with the brand of MISA and the concept of an event. Directs/Suggest ideas by assuring that the art has a visual appeal. Collaborate with Promotional Strategists with regard to design sprints and themes. Communicate with graphic designers by creative visual concepts. Serve as the eye of MISA’s visual concept.
<br>
<br>

<b>4. Visual reBranding</b>
<br>
Aims to <b>reinforce current policies of the COMM cluster</b> while also further developing the involvement of MISA through promotions. <b>Addresses issues for documentation, communication, and protocols for the COMMs cluster</b>.
<br>
<br>

<b>Policies</b>
<br>
    1. Streamlining visuals and elements of events to create cohesive and fundamental tools of reference for all designers.
<br>
    2. Establish MISa identity through collaboration and communication with officers via the structured process of protocols.
<br>
    3. Retention of Theme sketch deck.
<br>
    4. Retention of PRF and BRF
<br>
    5. Uniform process of documentation of contents by creating a database via gdrive and NOTION.
<br>
<br>

<b>Promotions</b>
<br>
    1. Further, enhance MISA socmed Involvement by <b>collaborating with other tech organizations</b>.
<br>
    2. Utilize MISA socmed apps such as IG and LinkedIn to promote the organization.
<br>
    3. <b>Update MISA Website</b> through the help of product designers. It would seem fitting that a tech organization such as MISA will continuously update all of its social media and websites while also being aesthetically pleasing and interactive.
<br>
<br>

<b>EXTERNAL PLATFORM</b>
<br>
<br>

<b>1. Integrate Web Design and Development (User Experience/User Interface)</b>
<br>
I plan to integrate User Interface and User experience design into MISA. Establish a platform where people who are interested in UI design and prototyping can be accommodated. Provide an avenue to create more opportunities for members to learn more about information management, technology, and design. This is a collaboration with the Marketing Cluster and the eServices cluster.
<br>
<br>
Why? - To be able to <b>establish product designers</b> as part of the officer mentorship program. Create <b>connections with people who are interested in design through technology</b>. UI/UX is a growing field that could potentially be a deal-breaker for the digital art industry. <b>Collaborate with other tech-related organizations and get insights from one another</b> since MISA is the bridge that unifies business, frontend and backend technology.
<br>
<br>

How? - For Marketing, my plan A is to collaborate with them in <b>creating connections with organizations such as UXSOC and GDSC</b>. My plan B is to hopefully <b>find partnerships with companies</b> that tackle UI and UX. 
<br>
<br>

For Eservices, have them collaborate with our <b>product designers to create websites that fit the needs of their clients</b>. Creating websites for clients and for MISA. Create web <b>design for clients that fits their business and brand strategy</b> through a strategic approach to information architecture, and branding.
<br>
<br>

For Communications - Establish product design as well as officer well-being and mentorship. <b>Create Product designers that craft design and wireframe solutions</b> that fit the clients' purpose and needs. <b>Implement workshops related to UI and UX design</b>. The main goal is to <b>start prototyping visually appealing websites on FIGMA</b> that targets the use cases and user experience.

</strong></p>
      
      <p v-else><strong>
        {{platform}}
      </strong></p>

      

      <template slot="footer">
        <n-button type="danger" @click.native="modals.classic = false"
          >Close</n-button
        >
      </template>
    </modal>
</div>
    
</template>
<script>
import { Button, Modal } from '@/components';
export default {
    components: {
    Modal,
    [Button.name]: Button
  },
  data() {
    return {
      modals: {
        classic: false
      }
    };
  },
  props: {
    name: String,
    position: String,
    image: String,
    platform: String,
    link: String
  }
}
</script>
<style lang="scss" scoped>
    .candidate-card{
      padding-bottom: 3rem;
      // border: 1px solid #DDDDDD;
      // transition: 0.2s;
      
      // &:hover{
      //   border: 1px solid #149da1;
      //   cursor: pointer;
      // }

      h4{
        margin: 12px 0px;
      }

      h5{
        margin: 0;
      }

      .candidate-card-text{
        // padding: 8px;
      }
    }

    
</style>