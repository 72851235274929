<template>
    <div>
        <div class="page-header page-header-smaller" filter-color="primary">
            <parallax
                class="page-header-image"
                style="background-image: url('img/elections-header.png')"
            >
            </parallax>
            <div class="content-center brand">
                <div class="container">
                <img class="n-logo" src="img/now-logo.png" alt="" />
                <h2 class="title homepage-title">MISA Executive Board 2022-2023 Elections</h2>
                </div>
            </div>
        </div>
        <div class="section section-about-us">
            <!-- IGNORE THIS: just an alternate button
            <div class="text-center">
                <button @click="goto('vote-btn')" class="btn btn-primary btn-lg btn-round btn-round" style="margin-top:-50px">Vote Now</button>
            </div> -->

            <div class="vote-now">
                <p><strong>Vote Now</strong></p>
                
            
            <div class="scroll-down" @click="goto('vote-btn')"></div>
            </div>
            <div class="container candidate-header">
                <h3>Candidates</h3>
                <div class="candidates-container">
                    <div class="candidate-item" v-for="candidate in candidates" :key="candidate.name" style="white-space: pre-wrap;">
                        <Candidate
                            :name=candidate.name
                            :position=candidate.position
                            :image=candidate.image
                            :platform=candidate.platform
                            :link=candidate.link
                        >
                        </Candidate>
                    </div>
                </div>
                <div class="vote-cta text-center" ref="vote-btn">
                    <h3>Ready to vote?</h3>
                    <p><strong>Login with your ID number and if you're eligible to vote, you will be redirected to the ballot where you will be able to cast your vote.</strong></p>
                    <a
                    href="/elections-login"
                    class="btn btn-primary btn-lg btn-round btn-round"
                    role="button"
                    >
                        Vote
                    </a>
                </div>
            </div>
        </div>  
    </div>
</template>
<script>
import Candidate from '../pages/components/Candidate.vue'
export default {
    components: {
        Candidate
    },
    methods: {
    goto(refName) {
        var element = this.$refs[refName];
        var top = element.offsetTop;

        window.scrollTo({
            top: top,
            left: top,
            behavior: 'smooth'
        });
        }
    },
    data() {
        return {
            candidates: [
                {
                    name: "Maverick Simon Tinio",
                    position: "President",
                    image: "img/mav-tinio2.png",
                    platform: `To achieve my vision for the organization, I would like to focus on the following: 1) Information Management Advocacy, 2) Increased Transparency and 3) Proper Documentation and Clear Definition of Roles and Protocols. 

Platform 1: Information Management Advocacy 

Maintain MISA Collaboration Laboratory 
● Continue on providing quality modules and activities that help teach different communities about basic IT Management. 

Conduct More External Initiatives 
● Main goal would be to show people what Information Management is and how important it is. Also, introduce MIS to them. 
● This will also help get MISA’s reach further. 
● Partner with other organizations/schools/etc. 
● This could be done through talks, workshops, etc. 
● Collaborate with the eServices Cluster for them to create the contents and to conduct/host the workshop itself. 
● Collaborate with the Events Cluster for overall handling of the initiative itself. 
● May collaborate w/ other orgs related to Information Management. 

Platform 2: Increased Transparency 

Calendar of Activities 
● Create 2 versions of Calendar of Activities: 1) for the whole organization and, 2) for the BETA. 
    ○ The Organization Calendar will be posted monthly to help the members schedule ahead of time for MISA’s events. Holidays, moratoriums and other important dates will also be included. 
    ○ The BETA Calendar will be for internal use and regularly be updated by the BETA members about their planned events, workshops, talks, and important meetings where every BETA is required to attend to. 

Centralized Workspace through Notion 
● Create multiple Notion Pages for EB, BETA and Officers. 
● Establish a template for all these pages for easy navigation. 
● Create a Navigation guide for the pages. 
    ○ Step by step guide on how to use Notion pages.
    ○ Indicate what should be included or not in the Notion page.
● BETA Calendar would be found in the Main Notion page.
● Announcements, important documents pages etc. should also be displayed here.

Leave of Absence Document for BETA
● Create a formal Leave of Absence Document for BETA members
    ○ To have proper documentation regarding which BETA member will not be available at a specific period of time.
    ○ This would help BETA members prepare ahead of time and delegate tasks to the other members who are available.

Platform 3: Proper Documentation and Clear Definition of Roles and Protocols 

MISA Officer Handbook/Officer Kit
● Proper definition of Officer and BETA Roles for the Officers to be aware of what is the scope and limitations of their jobs.
● Should include a step by step guide for officers and BETA who needs specific procedures to be done.
    ○ Should also include what to do and not to do.
    ○ Sample templates for forms may also be included.
● Establish what can be shared and not shared to the officers or BETA.
● The current OSG Officer Guide could be used as a basis.
● This handbook should be updated yearly by each cluster.
● The different online and onsite protocols should also be included in this document. The upcoming transition to onsite protocols should also be covered in this document.

Platforms for each cluster:

Office of the Secretary General
● Spearhead the creation of each cluster’s Officer Kits.
    ○ Guide other BETA members on how to structure the Officer Kit
    ○ This would serve as a step by step guide for officers and BETA who need specific procedures to be done.
    ○ The Officer Kits of each cluster may be based from the current OSG Officer Kit
● Manage the Calendar of Activities mentioned in Platform 2 and update if needed.

Office of the Executive Treasurer
● Initiate fundraisers to increase funds the organization would be able to expend for its events and projects and to donate to those in need.

Communications Cluster
● Establish an unique branding for each major event MISA to get our events recognized easier.
● Continue the utilization of social media platforms to further increase MISA’s reach. This would also help in showcasing the organization’s advocacy to the public.

eServices Cluster
● Information Management Advocacy (refer to Platform 1)
● As suggested by a fellow BETA member, the number of AVPs for Systems Documentation should be reduced to one while the number of AVPs for IT Skills Training and Evaluation should be increased to two. This would help in redistributing their workload. One for training officers and One for conducting workshops.
● Centralize all MISA websites for external entities to be able to locate what they are looking for in one site.

Events Cluster
● Information Management Advocacy (refer to Platform 1)
● Establish clear protocols for the transition to on-site classes.

Human Resources Cluster
● Information Management Advocacy (refer to Platform 1)
● Redefine the role of the AVP for Officer Relations.
    ○ AVP for Officer Relations would now have to spearhead each individual cluster’s team building activities
    ○ As per suggestion of a current EB member, Officer Relations can also assist OSG in setting dates for the internal events.
● Create an Interview Form that would be used for interviewing the aspiring officer applicants of MISA.
    ○ This would help in standardizing the Interviews for the applicants and having proper documentation for it.
● Continue Member Appreciation posts to boost member’s morale
● Continue MISAyang Samahan to guide members throughout the school year and to encourage members to participate in various MISA events 

Marketing Cluster
● Improve alumni connections by having a platform where they can communicate with the current members.
    ○ Create a Discord server where the alumni and the current MISA members will be able to bond.
    ○ Look into other platforms to allow better communication between alumni and current members that could be used in the future.
● Continue Marketing initiatives such as HEX, RLab, and Marketing Workshop.`,
                    link: "https://drive.google.com/drive/folders/1TnNDSqLHoCTp7JKJCgNSPuGRHlJe9BAC?usp=sharing",
                },
                {
                    name: "Veronica Gotangco",
                    position: "Secretary General",
                    image: "img/veronica-gotangco.JPG",
                    platform: `I can achieve this vision by trying to shorten and smoothen possible processes in the Office of the Secretary General starting from the cluster evaluations for both the BETA and the third-level officers as well as certain documents such as the project summary where it is indeed too long for Operations Managers to do. This year also marked the first time that the OSG has their own productivity workshops so I want to instill this value not only to those who are under the OSG but to everyone as well since organization is an excellent trait to have when we study and do organizational work.`,
                    link: "https://drive.google.com/drive/folders/1zczyUBMvpw1gLSxSSIHfzn9sN_V3y2PH?usp=sharing",
                },

                {
                    name: "Aaliyah Sangueza",
                    position: "Secretary General",
                    image: "img/aaliyah-sangueza.jpg",
                    platform: `The OSG has been under restructuring and the previous and current Secretary General and AVPs have exerted effort to improve the documentation processes and protocols within the organization. The implementations have significantly improved MISA’s operations, and my platforms are focused on strengthening the work processes in place and improve the system further. The following are my goals for MISA and OSG:

● Uphold MISA Cluster Goal Setting and Performance System
    o Implement a regular schedule for meetings to determine cluster goals and initiatives and communicate subcluster updates for transparency. The set cluster goals will be evaluated at the end of the semester through the evaluation system.
    o Cluster monthly reports serve to summarize the completed initiatives of the cluster, streamlined processes, concerns, and further improvements.
    o The clusters will be monthly rated by the Executive Board (EB) based on their Key Performance Indicators (KPIs).
    o Implement mid-semester and end of the semester evaluation forms to evaluate the cluster’s performance and completed initiatives. This will help clusters gauge their performance and improve based on the recommendations and comments.
    o BETA member evaluation forms will be included with the end of semester evaluation form to gain feedback on the BETA’s performance.
    o The evaluation forms will be improved to be more concise and straightforward.
    o The collected data from the evaluation forms will be significant in improving the processes and systems in place. It also promotes the organization’s effective information management and be more data driven.
    o In cooperation with the Human Resources cluster, the project performance management system form will be processed to be feedback reports that will be sent to the core team members. The qualitative and quantitative data will be useful for officer applications deliberation.

● Enhance processes in the Office of the Secretary General
    o Improvement of officer system
        ▪ Establish the roles and responsibilities of the Organizational Development Deputy like taking charge of the subcluster’s documentation.
        ▪ Continuous deputy deployment in MISA’s clusters
        ▪ Setting a talk or seminar for officers to familiarize themselves with the responsibilities of the role they will be applying for to avoid officer ghosting.
        ▪ Organize protocols for onsite activities
    o Improvement of OSG processes
        ▪ Implementation of request form for initiatives and collaborations with other clusters
        ▪ Zoom tracking form that takes note of the agenda for the use of zoom
        ▪ Improve transparency guidelines through regular updates.

● Standardize MISA’s workspace and announcement platforms
    o Utilize platforms as official workspaces for MISA
        ▪ Communication platform – primarily used for announcements and member engagement activities
            • Facebook
            • Discord
            • Zoom
            • Gmail
        ▪ Work platform
            • Notion
                o Notion guide for officers and members
                o Notion template that can be used by MISA’s cluster
            • Google Suite
                o Google Drive – file management and storage
                o Google Calendar – initiative and meeting invite
                o Manage the permission setting for access
    o Establish the controls for the communication platform and work platform to protect data.

● Establish cluster project partnership
    o Collaborate with MISA’s clusters to organize workshops or seminars that implement organizational development and promote MISA’s IM advocacy.

● Improve officer application and training
    o Organize training or workshops for officers and aspiring officers about OSG and its responsibilities. It will encourage more people to join the cluster.
    o Establish mentorship program between the officers and OSG BETA`,
                    link: "https://drive.google.com/drive/folders/13VWgUiTDV1jfONDRGsLMnUkgq1SCJUa_?usp=sharing",
                },

                {
                    name: "Mark Jiron Rotoni",
                    position: "Vice President for Communications",
                    image: "img/mark-rotoni2-min.png", 
                    link: "https://drive.google.com/drive/folders/1spPNcaB0iQG1kSyCN5Qklhlk9S6k21IJ?usp=sharing",
                },

                {
                    name: "Eliana Volante",
                    position: "Vice President for Communications",
                    image: "img/eliana-volante.png",
                    platform: `I aim to achieve that vision by implementing the following strategies:

COMMlaboration

Work under the Communications cluster may seem like a chore, given that Communications officers work tirelessly to produce quality publication materials and spiels. This monotonous feeling could be liberated given the right environment wherein work does not feel like work. And so, it is essential that the cluster fosters a collaborative environment in order to help relieve the pressure off of work and aid the members of the cluster to empower one another and influence each other’s passion and dedication.

Action Plans:
    ● revamp the creative process: On-boarding → Levelling-Off Meeting → Design Sessions
    ● On-boarding: initial meeting for the COMMS Creatives Team to be facilitated by the assigned COMMS BETA; introduce them to the cluster’s SOPs and orient them on their respective roles and responsibilities
    ● Levelling-off Meetings: serves as an icebreaker in order to initiate/foster the dynamics of the COMMS officers; initial brainstorming session for theme sketch
    ● Design Sessions: collaboration proper to be facilitated by the Lead Designer; accomplishing deliverables and collaborating with fellow officers to exchange thoughts and ideas

COMMformity and Standardization

Documentation systems and internal processes are essential in promoting transparency and facilitating a seamless creative process. As a cluster that is responsible for ensuring the proper dissemination of information and communication protocols, it is essential that internal processes and protocols are well-established and constantly maintained.

Action Plans:
    ● maintain the Publication Request Form (PRF) and the Blast Request Form (BRF) systems and strictly adhere to its protocols
    ● utilize Notion as the central workspace for the cluster to document MISA internal initiative requests
    ● emphasize and strictly integrate approval processes (i.e., screening and approval process as stated in the COMMS Officer Handling Guide)

COMMendation and Empowerment

Communications officers, composed of Creative Directors, Promotional Strategists, Video Editors, and Product Designers, are the driving force of the cluster. It is important to continuously empower them by providing them with opportunities to help them refine their skills and ensure their growth within and outside of the cluster. This is also extended to all members of the organization to help them explore areas under graphic design and promotions. Moreover, this opens up opportunities to collaborate with other clusters in MISA.

Action Plans:
    ● conduct bootcamps and workshops for skills training (e.g. Creative Director and Promotional Strategist Bootcamp)
    ● conduct monthly or bi-monthly COMM Along with Us hangouts
    ● collaborate with other clusters and conduct workshops that are open to all members of MISA
    ● introduce the Lead Designer role in the COMMS Creatives Team, who will serve as the point person for the COMMS BETA and project heads and facilitates the progress of the Creatives Team in accomplishing their deliverables for a specific event/initiative`,
                    link: "https://drive.google.com/drive/folders/1_kVewdKQgvXBsBsbXeAllCGE4vjxWBTm?usp=sharing",
                },

                {
                    name: "Sophia Lorraine Roxas",
                    position: "Vice President for eServices",
                    image: "img/sophia-roxas2.jpg",
                    platform: `REDISCOVER - Going back to one’s passions and creativity is a key point in developing the cluster. To rediscover these aspects would not only let each member do their work, but create purpose to their work.

INFORMATION DELIVERY - Organization within the cluster is crucial and utilizing Notion and Discord can be helpful on top of Google Drive. This is to separate planning, implementing and posting of projects made by the cluster. With a clear process and organization, transparency will follow-through to advise the members in their next steps.

SERVICE - As the cluster progresses, efficiency in teaching the officers their next set of skills determines the amount of projects taken. Therefore, I believe that the number of officers should be increased, especially the AVPs. Furthermore, connections through the rest of the organization can create opportunities for projects, which needs skilled officers to deploy.

ENGAGEMENT - Since workshops are held mostly for the developer pool, I would love to engage them in more interactions for a higher participation percentage. This would also help us in gauging the level of the future officers and current officers for the projects to be taken.`,
                    link: "https://drive.google.com/drive/folders/15taTNd9lZBYe0ANPBojo7xyXad11lBYN?usp=sharing",
                },
                
                {
                    name: "Jericho Arcelao",
                    position: "Vice President for Events",
                    image: "img/jericho-arcelao2.jpg",
                    platform: `SOCIAL Platform

Inspired by the power of social platforms to allow anyone to work, communicate, and push for good regardless of distance, this platform aims to position MISA and the Events Cluster into a catalyst for change and create a solid foundation as it transitions into the onsite setting.

S - Strategize for Onsite Return
● Onsite Working Group
    ○ Establish a joint working group of EB, BETA, Alumni, DISCS, COA, and Sanggu for communicating and strategizing for onsite operations.
● Comprehensive Transition Plan
    ○ Details concrete steps each cluster has to take as the organization adjusts to the onsite setting.
    ○ For events, this will be figuring out which events will first go onsite/remain online, how core teams will be assembled, how officers will work with each other (i.e. blended approach), among other minutia.
● Refresh Onsite Documentation
    ○ The Onsite Documentation project started last term will be continually updated along with the transition plan in line with feedback from the Onsite Working Group.

O - Orient Cluster Operations
● Formalize contingencies and processes
    ○ Some protocols established in the cluster were only designed as band-aid solutions. Working with the OSG, this intends to locate and formalize such processes and terms across the organization and convene towards a new standard.
● Create officer kits
    ○ Many of the cluster’s officers have similar duties across the events lineup (e.g. PDs, Prog, Log). This aims to standardize these documents to complement the onboarding process and provide a standard kit that can be adjusted to work for each event.

C - Charter a Mindful Lineup
● Delegate previously inter-cluster events to their respective cluster
    ○ As the cluster is expecting to face even heavier workloads in the upcoming term, this intends to drop the existing inter-cluster events (i.e. LDB and Networking Night) from the existing Events lineup. How these initiatives will continue will be under the complete discernment of their respective cluster.
    ○ As such, the only events the cluster intends to keep are Jumpstart, GA, IM Summit, Rewind, and CoLab.
● Create a consistent branding identity for all events
    ○ To make the events lineup more memorable, this aims to collaborate with the Comms cluster in coming up with a branding identity for every event.

I - Integrate Long-term Engagement Initiatives
● Create mixer hangouts in collaboration with HR
    ○ This intends to augment the existing EventsSuperFamily initiatives by working with the HR cluster to come up with inter-core team hangouts to help officers in the cluster to interact with their previous teammates and meet new ones along the way.
● Highlight member wellness in core teams
    ○ As cases of ghosting officers and burnout continues to manifest, this looks to collaborate with the HR cluster in providing avenues for members to be open about their difficulties and have a breathing space (e.g. re-delegating workload, calling for substitutions, etc.).

A - Advocate for CoLab
● Create a dedicated CoLab core team
    ○ To bring CoLab back into the spotlight, the cluster intends to collaborate with the HR cluster in establishing a term-long initiative to look for partners and establish volunteering opportunities.
    ○ The cluster wishes to participate in this effort through establishing a core team (i.e. year-long officers that manage the overall operation of the initiative) and a volunteer pool (i.e. members interested in joining volunteering initiatives held throughout the term).

L - Look for New Learning Opportunities
● Provide workshops for various event roles and duties
    ○ This intends to establish small-scale workshops to expose interested members to the various duties performed within the cluster (e.g.managing a PMS, performing programs and logistics operations) andhelp them gain an idea on what working in the cluster is like.
● Switch to dedicated work platforms
    ○ Some members are having difficulties as their org channels (e.g.Messenger, Discord) are mixed in with their personal communications.This intends to look into the use of dedicated org platforms (e.g. Monday,MS Teams, GChat, Slack) to help members separate their personal andorg commitments.`,
                    link: "https://drive.google.com/drive/folders/1_GBPfn76HuzGXuodhVtHei_5o5B1cthd?usp=sharing",
                },
                {
                    name: "Leanne Hailey Go",
                    position: "Vice President for Events",
                    image: "img/leanne-go.JPG",
                    platform: `1. Conduct events and projects that promote the organization's mission and vision.

The current events line up is already a very strong one and I believe continuing this existing line up of events will be of great benefit to the organization. The current events strongly promote the organization’s mission, advocating for effective information technology. More than this, however, I believe that there is still plenty of room for improvement when it comes to the organization’s events. Hence, I hope to be able to include an event like “Tambay Week” as I believe it is also just as important to be able to give MISAns an opportunity to unwind and meet fellow MISAns in the organization.

2. Further develop the Events Superfamily initiative.

It’s no doubt that this initiative has greatly improved this school year and has made a big positive impact towards third-level officers in events. That being said, I want to be able to continue building on this current initiative to attempt to develop a more long-term and sustainable events superfamily. With a stable events superfamily system in place this year, there is now opportunity to curate bigger initiatives that can involve more events officers, past, current, and future. Such initiatives can include activities like video call hangouts/parties, inter-project officer activities, and so much more. Through this addition, events officers will be able to continue to feel part of the family whether the project and event they are a part of is completed, on-going, or still coming soon.

3. Improve communication and foster good work environments for officers.

Being part of several events this year, I did experience and notice some gaps and differences between clusters working together. And since different clusters inevitably have to work hand in hand during events, it is of great importance to ensure good communication and dynamics among officers. Hence, I plan to place emphasis on conducting regular group dynamics among coreteams to create a more conducive and positive work environment for officers.

4. Create a transition plan.

While the development of the on-site handbook will surely continue in the next year, I believe it is also essential to create a transition plan for events specifically. Although the online setup has put events through several obstacles, there is no doubt that this new setup has brought new and better opportunities for events to become bigger, better, and more efficient. To name an event, the flagship event of MISA, IM Summit, is a perfect example of an event that could potentially remain an online event. The successful execution of a fully-online IM Summit the past 2 years has presented the organization with better opportunities both financially and logistically. More than this, however, some events still face major obstacles due to the online limitation. Events like Jumpstart and the Leadership Development Bootcamp, are among the few events that would face better successes through an onsite event. These being said, it is important that the Events BETA begin to thoroughly study and analyze the annual events MISA holds. As a result, the Events BETA will be able to create transition plans for events that can guide future Events officers once onsite activities are permitted.`,
                    link: "https://drive.google.com/drive/folders/1pVvGSzC2ygKdHaMi0bZVX4wuXqgUtsjE?usp=sharing",
                },

                {
                    name: "Ethan Miguel Tan",
                    position: "Vice President for Human Resources",
                    image: "img/ethan-tan.png",
                    platform: `This vision mainly entails tackling MISA HR in 3 fronts:
    1. Interaction within the Organization
    2. Interaction outside the Organization

For internal interaction within the org, it would be mainly building upon the relatively successful rework of this year’s HR through Member Relations and Officer Relations. I would like to implement more simpler ‘spontaneous’ engagements alongside our seasonal get togethers such as Christmas party and summer blowouts. These smaller offseason activities give more opportunitiesfor membersto get to know one another more frequently. For member relations, the biggest concern was member turnout for large scale engagements and get togethers. I would want to establish in the preparation phase of these initiatives that their activities should be scalable depending on how many participate in the initiatives. As the goal of these initiatives is to solidify the connection between the members the org, it would be desirable that many people would attend the event. For this to happen we would need to set the culture of the org as early as possible to our members at the earliest stage of their stay in the org i.e., freshman year. This means that initiatives such as jumpstart and the mentorship program should be utilized as avenues to establish the org’s culture and advocacy, even beyond our online setting. A possible activity would be our BETA and EB officers would have dedicated get togethers with the people in the program to have a snippet on how it like is to be an officer in MISA.

To refine our interaction outside the organization, it would entail revamping/polishing IM advocacy and COLAB. The success of this years COLAB brought many points of improvement. Firstly, for our own officers, the preparation of the initiative should be more structured with a proper core team with help from different clusters, parallel to IM Summit’s preparation. We should also involve the whole organization with this endeavor. From the get-goin member applications,we should inform them of MISA’s big advocacy to increase awareness and toalso attractmore volunteers for COLAB.To make the advocacymore felt in the organization, it possible to set initiatives distributed through the whole school year, not just in one activity in COLAB. This could be done through multiple COLAB engagements with multiple partners (for example once a year), but also smaller initiatives such as talks and workshops, partnering with outside organizations, or other academic institutions, still in the spirit of IM advocacy. We also need to prepare smaller initiatives for IM advocacy as it is difficult to guarantee partners for the established COLAB initiatives.`,
                    link: "https://drive.google.com/drive/folders/1mYPRum1bRR1OpbfyI-TSDSDgX7YRnXCC?usp=sharing",
                },
                {
                    name: "Shaira Eunice Aban",
                    position: "Vice President for Marketing",
                    image: "img/shaira-aban.png",
                    platform: `In line with this vision, I aim to maintain and create new connections, centralize and organize cluster information, and provide different avenues to connect with organization members.

First, in maintaining and creating new connections, I plan to utilize Corporate Relations, Organizational Relations, and Alumni Relations in providing more opportunities for MISA. I believe having continuous partnerships with companies, school organizations, and alumni is truly beneficial in terms of improving MISA’s presence, funding its projects, providing internships, and producing quality initiatives. As such, for Corporate Relations, I plan to add more online shops or companies that sell goods/services online as contacts and persuade them to sponsor by sending our carefully drafted proposals. As for our existing partnerships with companies like P&G, Accenture, and others, I would also make sure that we continue our good relationship with them by keeping up to their standards. For Organizational and Alumni Relations, I aim to reach out to more organizations and alumni—with the help of my officers—to continue expanding our connections. All in all, I will maximize all three subclusters in supporting and helping MISA execute its plans for the school year.

Next, I aim to centralize and organize cluster information by creating a dashboard per subcluster (if applicable) where all links and information pertaining to the ropes of the marketing cluster can be found. This will include links to the Notion page, marketing database, marketing proposals, MOAs, and ongoing partnerships, among all others. Here, I also plan to continue updating emails in the marketing database and arranging them as I believe we could improve sending out proposals by prioritizing those who will likely respond. Moreover, this initiative will also significantly increase the transparency within the cluster—making the officers more aware of its processes and deliverables. And therefore, along with this, I will make sure strict policies on data privacy protection will be imposed. Additionally, centralizing cluster information also includes encouraging officers to regularly send their updates and fostering healthy discussions in our primary communication channel.

Lastly, I also plan to provide different avenues to connect with other organization members, given that we will really need their help in accomplishing MISA’s partnership responsibilities. As such, I believe this will educate them more about the cluster and promote connectedness as the cluster should not stand on its own. Specifically, I aim to do this by continuing the conduct of cluster workshops that are open to all members of MISA that would hopefully encourage them to be active in the organization as well. Along with this, I would also communicate with other clusters for joint initiatives so that we could provide worthwhile opportunities to the members (quality > quantity). And if their schedule permits, I would invite them to occasional hangouts, i.e., “Marketing & Chill,” so that we’re not only all about doing work and we could get to know more people in the organization.`,
                    link: "https://drive.google.com/drive/folders/1IGHlJxP8_AaABv7Hegu8oNpz49bGHXc5?usp=sharing",
                },
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
    .homepage-title {
        font-size: 32px;
    }
    .candidate-header {
        margin-top: 50px;
    }
    .candidates-container{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(340px , 1fr));
        gap: 16px;
        margin-top: 40px;
    }

    .vote-cta {
        margin: 64px auto ;
        max-width: 420px;
    }

    .vote-now {
        display: flex;
        font-size: 20px;
        justify-content: center;
        flex-direction: row;
    }

    .scroll-down {
        height: 50px;
        width: 30px;
        border: 2px solid black;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 80px;
        border-radius: 50px;
        cursor: pointer;
    }
    .scroll-down::before,
    .scroll-down::after {
        content: "";
        position: absolute;
        top: 20%;
        left: 50%;
        height: 10px;
        width: 10px;
        transform: translate(-50%, -100%) rotate(45deg);
        border: 2px solid black;
        border-top: transparent;
        border-left: transparent;
        animation: scroll-down 1s ease-in-out infinite;
    }
    .scroll-down::before {
        top: 30%;
        animation-delay: 0.3s;
        /* animation: scroll-down 1s ease-in-out infinite; */
    }

    @keyframes scroll-down {
        0% {
            /* top:20%; */
            opacity: 0;
        }
        30% {
            opacity: 1;
        }
        60% {
            opacity: 1;
        }
        100% {
            top: 90%;
            opacity: 0;
        }
    }
</style>